import { useAuth } from '@/config/auth/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { Markdown, Modal } from '@/components';
import { Button, Modal as SemiModal } from '@douyinfe/semi-ui';
import { DOMUtils } from '@/utils';

export enum FeatureName {
  custom_api = 'custom_api',
  sync_service = 'sync_service'
}

export const useWhiteList = (type: FeatureName, options?: {
  rollback: boolean
}) => {
  const { user } = useAuth();
  const { rollback = false } = options || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentModal, setCurrentModal] = React.useState<ReturnType<typeof SemiModal.info>>();

  useEffect(() => () => {
    if (currentModal) {
      currentModal.destroy();
    }
  }, [currentModal]);

  const checkWhitelist = useCallback(() => _.includes(user?.allowed_services, type), [user?.allowed_services, type]);

  const openRequestModal = useCallback((modalOptions?: {title?: string}) => {
    const { title } = modalOptions || {};
    const modal = Modal.info({
      title: title || t<string>('whitelistModal.title', { ns: 'translation' }),
      maskClosable: false,
      centered: true,
      content: (
        <div className="mt-[32px]">
          <Markdown>{t<string>('whitelistModal.description', { ns: 'translation', name: t(`serviceDescription.${type}`) })}</Markdown>

          <div className="semi-modal-footer">
            <div>
              <Button
                theme="solid"
                type="secondary"
                onClick={() => {
                  DOMUtils.open(process.env.REACT_APP_TELEGRAM_BUSINESS_CONTACT as string, '_blank');
                  modal.destroy();
                }}
              >
                {t('whitelistModal.joinTelegram', { ns: 'translation' })}
              </Button>

              <Button
                theme="solid"
                type="primary"
                onClick={() => {
                  DOMUtils.open(`mailto:${t('contact.salesEmail')}`);
                  modal.destroy();
                }}
              >
                {t('whitelistModal.emailUs', { ns: 'translation' })}
              </Button>
            </div>
          </div>
        </div>
      ),
      afterClose() {
        if (rollback) {
          navigate(-1);
        }
      },
      footer: null
    });
    setCurrentModal(modal);
  }, [type]);
  return {
    checkWhitelist,
    openRequestModal
  };
};
